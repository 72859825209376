<template>
    <div class="chat-wrap">
        <div v-if="!hasPermission" class="permission-popup">
            <div class="corner">
                <img src="../assets/diamond.png" class="diamond" />
            </div>
            <div class="groupname">{{ groupname }}</div>
            <div class="intro">{{ payintro }}</div>
            <div class="icons">
                <div class="icon-wrap">
                    <img src="../assets/membericon.png"/>
                    <div class="icon-desc">免注册进群</div>
                </div>
                <div class="icon-wrap">
                    <img src="../assets/chaticon.png"/>
                    <div class="icon-desc">匿名畅快聊</div>
                </div>
            </div>
            <button v-if="showrechargebtn" class="recharge"  @click="recharge">¥{{ payprice/100 }} 立即进群</button>
            <button v-if="!showrechargebtn" class="recharge-disable" >支付处理中...</button>
            <div class="recoverpaid" @click="recoverpaid">已支付？点击此处进群</div>
        </div>

        <div class="header" ref="header">
            <div class="homebtn" @click="backhome"></div>
            <h1 class="chat-title" v-on:dblclick="copygid">{{groupname}} ({{usersnum}}人在线）</h1>
            <button class="sharebtn" @click="share">分享</button>
        </div>
        <div class="content" ref="content" @scroll="scrollEvent" :class="{ 'blur': !hasPermission }">
            <div>
            <chatCellVue 
            v-for="(item, index) in messagesList" 
            :key="index" 
            :info="item"
            :gid="gid"
            @like="(msgId)=>{sendLike(msgId, index)}">
            </chatCellVue>
            </div>
        </div>
        <div class="footer" ref="footer" :class="{ 'blur': !hasPermission }"> 
            <div class="footer-item" v-if="coldtime<1">
                <textarea ref="messaginput" class = "messaginput" placeholder="说说你的想法吧~" rows="1"  v-model="message" ></textarea>
                <button @click="sendMessage" >发送消息</button>
            </div>
            <div v-else>
                <input class = "messaginput" :disabled="true" :placeholder="`发言排队中（${coldtime}秒）,先想想说什么吧`" >
                <button class="disabledsendbtn" disabled="true">发送消息</button>
            </div>
        </div>
        <!--<div class="footer" v-if="coldtime>0"> 
            
        </div>-->
        <!-- 这个条看起来不太舒服，先不搞了
        <div class="download-guide" v-if="ifshowdownloadtips">
            <span class="download-text">下载app独享发图、撤回、免排队特权</span>
            <div class="download"><button @click="downloadapp"><img src="../assets/apple.png" /><span>立即下载</span></button></div>
            <button class="close" @click="closeTips"><img src="../assets/close.png" /></button>
        </div>
        -->
        <div :hidden="true">
            <vue-qr ref="shareqrcode"  class="shareimg" id="picurl"  :logoSrc="qrimageUrl" :text="qrText" :size="500"></vue-qr> 
        </div>

        <div v-if="showpayqr" :hidden="true">
            <vue-qr ref="wxpaycode" class="wxpayimg" id="wxpayurl" :logoSrc="qrwxUrl" :text="wxpayText" :size="500"></vue-qr> 
        </div>

    </div>
</template>

<script>
import {getCookie,setCookie,delCookie,addHistoryGroup} from "../func/util"
import {postGroupInfo,postChatHistory,postGroupSysInfo,postInitGroup,postBuyGroupTicket,postGroupPaidStatus,postRecoverGroupPay,postGetWxOpenid,postGetWxSdkSign} from "../func/requestHandler"
import globaldata from "@/config/globaldata"
import chatCellVue from "@/components/chatCell.vue"
import VueQr from "vue-qr"
import wx from 'weixin-js-sdk';
var ws = null //全局的websocket对象

export default {
    props:{
        urlid: String,
        nickname: String,
    },
    components:{
        chatCellVue,
        VueQr,
    },
    metaInfo() {
        return {
            title: this.pageName,
            meta: this.metaList,
        };
    },
    data(){
        return {
            groupname: "在此",//群聊名，在create时会查询更新
            usersnum:0,//当前活跃的用户数
            coldtime:10,//当前用户发言冷却时间
            gid: 0,//群id，会根据url解析出来
            uid: 0,//用户uid，create登录时会写入
            uniqueid :0,//用户uniqueid,create登录时会写入
            // avatar:"",//用户头像，默认为用户名第一个字符
            // nickname:"",//用户昵称，create登录时会写入
            message:"",//记录用户发送消息时的model值
            cansend:false,//当前用户是否可以发言，和coldtime相关
            hasPermission: true, // 用户是否有权限进入当前网页
            payprice:"",//付费价格,单位为分
            payintro:"",//付费介绍

            /** messageList是所有消息的数组，结构为
             *  [
                *  {
                    msg:rawmsg.msg,
                    msgId:rawmsg.msgId,
                    type:rawmsg.type,
                    senderName:rawmsg.senderName,
                    senderAvatar:rawmsg.senderAvatar,
                    senderId:rawmsg.senderId,
                    likeNum:rawmsg.likeNum,
                    ifLike:iflike
                    },
                    {},{}...
                ]
             */
            messagesList:[],
            
            //SEO优化的meta数组
            pageName: "",
            metaList: [
                {
                name: "description",
                content: "",
                },
                {
                name: "keywords",
                content: "",
                }
            ],

            //下载提示的标识
            ifshowdownloadtips:false,

            //分享二维码弹窗的设置
            dialogVisible:false,//是否显示弹窗
            qrimageUrl:require("../assets/qrlogo.png"),//icon路径
            qrText:window.location.href,//分享文案

            //微信支付二维码的设置
            showrechargebtn:true,
            showpayqr:false,//默认为false，为了在赋值支付地址后改为true，才真正触发渲染
            qrwxUrl:require("../assets/wxpay.png"),//微信支付icon路径
            wxpayText:"",//微信支付地址
        };
    },
    computed:{
        avatar(){
            return this.nickname ? this.nickname[0] : "";
        }
    },
    watch:{
        message(){
            let elem = this.$refs.messaginput;
            elem.style.height = 'auto';
            //elem.scrollTop = 0;
            console.log("scrollHeight", elem.scrollHeight)
            let height = elem.scrollHeight > 80 ? 80 : elem.scrollHeight - 10;
            elem.style.height = height < 26 ? '26px' : height+'px'
        }
    },
    mounted() {
        // 针对微信打开的情况，需要提前获取一些授权
        this.wxauthorize();
    },
    methods: {
        ////获取微信openid相关调用
        wxauthorize() {
            //this.$message.info("触发了wxautorize")
            //1、如果不是在微信中打开，直接返回
            const ua = navigator.userAgent.toLowerCase();
            const isWeChat = /micromessenger/.test(ua);
            if(!isWeChat){
                //this.$message.error("不在微信里！")
                console.log("wxauthorize:不是在微信中打开，返回")
                return;
            }
            //2、如果已经有openid，返回
            if(getCookie("wxopenid")){
                //this.$message.error("已经有openid了："+getCookie("wxopenid"))
                console.log("wxauthorize:已经有openid了，返回")
                return;
            }
            //3、如果已经是微信回调了code的页面，需要处理回调，用code换openid
            const nowurl = window.location.href;
            const queryString = nowurl.split('?')[1];
            const params = new URLSearchParams(queryString);
            const code = params.get('code');
            //this.$message.error("code:"+code)
            if (code) {
                // 3.1 如果存在code参数，处理微信回调
                this.onWechatCallback(code);
            }else{
                console.log("获取code")
                //3.2 没有code的情况，先请求code
                //文档:https://developers.weixin.qq.com/doc/offiaccount/OA_Web_Apps/Wechat_webpage_authorization.html
                const appId = globaldata.WXAPPID;
                const redirectUri = encodeURIComponent(window.location.href.split('#')[0]);
                const scope = 'snsapi_base'; // 或者使用 snsapi_userinfo 获取用户信息
                //const state = '';
                //const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`;
                const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}#wechat_redirect`;

                //console.log("url:"+url)
                // 重定向用户到微信授权页面
                window.location.href = url;
            }
            
        },
        // 处理微信回调，通常是在回调页面中实现
        onWechatCallback() {
            //var that = this
            //this.$message.error("onWechatcallback!")
            const url = window.location.href;
            const queryString = url.split('?')[1];
            const params = new URLSearchParams(queryString);
            const code = params.get('code');
            if (code) {
                // 调用后端API，传入code，后端将code交换为access_token和openid
                postGetWxOpenid(code,(result) => {
                    if (result.succeed) {
                        //将openid存到cookie
                        console.log("wxopenid:"+result.openid)
                        /*that.$confirm('openid:'+result.openid, {
                                type:'info',
                                closeOnClickModal:true
                        })*/
                        setCookie("wxopenid",result.openid)
                    }
                    else{
                        console.log("getwxopenid失败："+result.message)
                    }
                })
            }
        },
        ////获取微信openid结束


        checkPermission() {
            var that = this
            postInitGroup(that.gid, (result)=>{
                console.log("aaaaaa"+result)
                if(result.succeed){
                    //如果当前不需要付费
                    if(result.price == 0 ){
                        //如果当前用户已经无需付费，则需要清空本地支付cookie，避免重复计费
                        delCookie('payid-'+that.gid)
                        that.hasPermission = true;
                    }
                    else{
                        //如果发现当前需要付费，先查查当前用户有无付款记录；
                        //这种情况是用户跳转支付了，重新回到页面时，页面会刷新
                        //先看看有无支付历史
                        var payid = getCookie('payid-'+that.gid)
                        //如果没有支付记录，直接返回无权限
                        if(payid == null){
                            that.hasPermission = false
                        }
                        //有支付记录，开启自动查询，并弹窗提示
                        else{
                            //先扭转状态为不可访问
                            that.hasPermission = false
                            //先开启查询
                            let intervalId = setInterval(() => {
                            postGroupPaidStatus(payid, (result) => {
                                if(result.succeed) {
                                // 支付成功，刷新当前页面
                                clearInterval(intervalId);
                                window.location.reload();
                                } else {
                                // 不做任何操作
                                }
                            });
                            }, 1000);
                            //再弹窗提示
                            that.$confirm('支付结果确认中,请稍候..', {
                                distinguishCancelAndClose: true,
                                confirmButtonText: '我已支付',
                                cancelButtonText: '放弃支付',
                                type:'info',
                                closeOnClickModal:false
                                })
                                .then(() => {
                                    //如果点击”我已支付“，立即刷新当前页面，重新触发进群，由服务端判断是否需要付费
                                    clearInterval(intervalId);//清除查询
                                    window.location.reload();
                                })
                                .catch(action => {
                                    console.log("放弃支付："+action)
                                    //如果点放弃支付或点了x，需要二次确认
                                    that.$confirm('是否放弃本次支付？', '放弃支付？',{
                                        distinguishCancelAndClose: true,
                                        confirmButtonText: '继续等待',
                                        cancelButtonText: '放弃支付',
                                        type:'warning',
                                        closeOnClickModal:false
                                        })
                                        .then(() => {
                                            //如果点击”继续等待“，不做任何操作
                                            clearInterval(intervalId);//清除查询
                                            window.location.reload();
                                        })
                                        .catch(action => {
                                            //如果用户点击的是"放弃支付"，清空支付信息
                                            if(action === 'cancel'){
                                                //放弃支付，清空cookie再loading
                                                delCookie('payid-'+that.gid)
                                            }
                                            clearInterval(intervalId);//清除查询
                                            window.location.reload();
                                        });
                                });
                        }
                    }
                    that.payintro = result.intro
                    that.payprice = result.price
                }
            })
        },
        //付费进群
        recharge() {
            var that = this;
            that.showrechargebtn = false //隐藏支付按钮
            var wxopenid = getCookie("wxopenid") ? getCookie("wxopenid"):"" 
            //var kind = "ysm";//之前用的易收米服务，微信支付跑顺后可废弃
            var kind = "wechat"
            /*测试环境自动免支付
            if(!globaldata.IS_RELEASE){
                kind = "testpay";//测试环境使用testpay
            }*/
            //that.$message.error("支付用的openid:"+wxopenid)
            postBuyGroupTicket(kind,wxopenid,that.gid,(result) => {
                if (result.succeed) {
                    //获取当前是在h5还是web
                    var platform = result.platform//1，在微信内，2，在普通H5内，3，在pc端web页

                    if (kind == "testpay") {
                        //测试环境自动跳转到激活链接完成支付激活
                        let urlbody = globaldata.test_API_PREFIX+globaldata.test_SERVER_HOST+":"+globaldata.test_API_PORT
                        console.log("testpay-result:",result)
                        let payresult = result.payresult;
                        let redirect_url = urlbody + payresult.redirect_url;
                        let payid = payresult.payid;
                        //1、先向cookie里储存payid，因为重定向之后页面会刷新，需要在cookie中记录
                        setCookie('payid-'+that.gid,payid)
                        //2、在新窗口重定向掉redirecturl
                        window.open(redirect_url,'_blank');
                        //3、页面刷新,页面刷新后会根据cookie的值开启支付信息查询
                        window.location.reload();
                    
                    }
                    else if(kind == "wechat"){
                        console.log("wechatpay-result:",result)
                        let payresult = result.payresult;
                        let redirect_url = payresult.redirect_url;
                        let payid = payresult.payid;
                        //1、先向cookie里储存payid，因为重定向之后页面会刷新，需要在cookie中记录
                        setCookie('payid-'+that.gid,payid)
                        //2，分情况处理链接
                        if(platform == 1){
                            //platform1:在微信里打开：
                            //获得的prepay_id如："prepay_id=wx15121223885851d0c546a16d3ca2200001"
                            //使用微信的jsapi调起微信支付
                            //1、先给服务器请求一个signature，传入noncestr、timestamp、url，服务器给回signature
                            const nowurl = window.location.href.split('#')[0];
                            postGetWxSdkSign(payresult.noncestr,payresult.timestamp,nowurl,(signresult)=>{
                                if (signresult.succeed) {
                                    wx.config({
                                        debug: false,
                                        appId: globaldata.WXAPPID,
                                        timestamp:payresult.timestamp,
                                        nonceStr: payresult.noncestr,
                                        signature: signresult.signature,
                                        jsApiList: ['chooseWXPay']
                                    }); 
                                    wx.ready(() => {
                                        // eslint-disable-next-line no-undef
                                        WeixinJSBridge.invoke('getBrandWCPayRequest', {
                                        "appId": globaldata.WXAPPID,   //公众号ID，由商户传入    
                                        "timeStamp": payresult.timestamp,   //时间戳，自1970年以来的秒数    
                                        "nonceStr": payresult.noncestr,      //随机串    
                                        "package": payresult.redirect_url,
                                        "signType": "RSA",     //微信签名方式：    
                                        "paySign": payresult.paysign,//微信签名
                                        },
                                        function(res) {
                                            if (res.err_msg == "get_brand_wcpay_request:ok") {
                                                // 使用以上方式判断前端返回,微信团队郑重提示：
                                                //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                                                window.location.reload();
                                            }else{
                                                delCookie('payid-'+that.gid)
                                                that.showrechargebtn = true //隐藏支付按钮
                                                
                                                return
                                            }
                                        });
                                        
                                    });
                                    
                                }else{
                                    that.$alert(signresult.message)
                                }
                            })
                            

                        }else if(platform == 2){
                            //platform2:在普通H5里打开，只需要跳转就行
                            //微信内打开或在普通H5打开，点击后openurl就行，注意服务端返回的url其实是有区别的，微信内打开的url和普通h5打开的不一样
                            let link = document.createElement('a');
                            link.href = redirect_url;
                            //link.target = '_blank';
                            document.body.appendChild(link);
                            link.click();
                            //页面刷新,页面刷新后会根据cookie的值开启支付信息查询
                            that.showrechargebtn = true //显示支付按钮
                        }
                        else if(platform == 3){
                            //platform3：PC内打开
                            //开启状态查询：
                            let intervalId = setInterval(() => {
                                postGroupPaidStatus(payid, (result) => {
                                    if(result.succeed) {
                                    // 支付成功，刷新当前页面
                                    clearInterval(intervalId);
                                    window.location.reload();
                                    } else {
                                    // 不做任何操作
                                    }
                                });
                            }, 1000);
                            //在PC端打开，弹出弹窗给用户扫码，同时开始查询支付状态
                            that.wxpayText = redirect_url//更新地址
                            var num = that.payprice/100
                            that.showpayqr = true
                            this.$nextTick(() => {
                                setTimeout(() => {
                                const htmlval = this.$refs.wxpaycode.$el.outerHTML
                                that.$alert(htmlval+'<div style="font-size:25px;margin-bottom:20px;">¥'+num+'</div><div>请使用微信扫码支付</div>', '微信支付', {
                                    confirmButtonText: '确定',
                                    showConfirmButton:false,
                                    dangerouslyUseHTMLString: true,
                                    center:true,
                                    callback: () => {
                                    }
                                });
                                that.showpayqr = false//销毁二维码组件
                                that.showrechargebtn = true //显示支付按钮
                                }, 1000); // 延迟 1000 毫秒
                            });
                        }
                        else{
                            //其他情况直接新窗口打开页面
                            window.open(redirect_url,'_blank');
                            //3、页面刷新,页面刷新后会根据cookie的值开启支付信息查询
                            window.location.reload();
                        }
                        
                    }
                    else if(kind == "ysm"){
                        //如果使用易收米支付
                        console.log("ysmpay-result:",result)
                        let payresult = result.payresult;
                        let redirect_url = payresult.redirect_url;
                        let payid = payresult.payid;
                        //1、先向cookie里储存payid，因为重定向之后页面会刷新，需要在cookie中记录
                        setCookie('payid-'+that.gid,payid)
                        //2，分情况处理链接
                        if(platform == 1 || platform == 2){
                            //微信内打开或在普通H5打开，点击后openurl就行，注意服务端返回的url其实是有区别的，微信内打开的url和普通h5打开的不一样
                            let link = document.createElement('a');
                            link.href = redirect_url;
                            //link.target = '_blank';
                            document.body.appendChild(link);
                            link.click();
                            //页面刷新,页面刷新后会根据cookie的值开启支付信息查询

                            that.showrechargebtn = true //显示支付按钮
                            //window.location.reload();
                        }
                        else if(platform == 3){
                            //开启状态查询：
                            //先开启查询
                            let intervalId = setInterval(() => {
                                postGroupPaidStatus(payid, (result) => {
                                    if(result.succeed) {
                                    // 支付成功，刷新当前页面
                                    clearInterval(intervalId);
                                    window.location.reload();
                                    } else {
                                    // 不做任何操作
                                    }
                                });
                            }, 1000);
                            //在PC端打开，弹出弹窗给用户扫码，同时开始查询支付状态
                            that.wxpayText = redirect_url//更新地址
                            that.showpayqr = true
                            this.$nextTick(() => {
                                setTimeout(() => {
                                const htmlval = this.$refs.wxpaycode.$el.outerHTML
                                that.$alert(htmlval+'<div style="font-size:25px;margin-bottom:20px;">¥'+num+'</div><div>请使用微信扫码支付</div>', '微信支付', {
                                    confirmButtonText: '确定',
                                    showConfirmButton:false,
                                    dangerouslyUseHTMLString: true,
                                    center:true,
                                    callback: () => {
                                    }
                                });
                                that.showpayqr = false//销毁二维码组件
                                that.showrechargebtn = true //显示支付按钮
                                }, 1000); // 延迟 1000 毫秒
                            });
                        }
                        else{
                            //其他情况直接新窗口打开页面
                            window.open(redirect_url,'_blank');
                            //3、页面刷新,页面刷新后会根据cookie的值开启支付信息查询
                            window.location.reload();
                        }
                        
                    }
                }
                else{
                    that.showrechargebtn = true //显示支付按钮
                    console.log("支付result：",result)
                    that.$confirm(result.message, '支付出错', {
                        confirmButtonText: '确定',
                        showCancelButton:false,
                        closeOnClickModal:false,
                        type: 'error'
                        }).then(() => {
                            //如果platform是微信内，先简单粗暴清除掉wxopenid
                            if(result.platform == 1){
                                delCookie("wxopenid")
                            }
                        }).catch(() => {
                    });
                }
            });
            
        },
        recoverpaid() {
            var that = this
            this.$prompt('请输入6位入场券id，如:ABCDEF；支付成功后，您可在微信支付的“账单详情”中，查看“商品”名即可看到入场券（商品名如:群名-入场券:ABCDEF）', '恢复购买', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                closeOnClickModal:false,
                inputPattern: /^[a-zA-Z]{6}$/,
                inputErrorMessage: '需6位字母，不区分大小写'
            }).then(({ value }) => {
                //输入code后
                console.log("code:",value)
                postRecoverGroupPay(value,that.gid,(result) => {
                    if (result.succeed) {
                        this.$confirm('恢复成功，点击确定按钮即可进群', '恢复成功！', {
                            confirmButtonText: '确定',
                            showCancelButton:false,
                            type: 'success'
                            }).then(() => {
                                window.location.reload();
                            }).catch(() => {
                                window.location.reload();
                        });
                    }
                    else{
                        this.$confirm('输入的订单号无效或不存在，请检查后重试', '验证失败', {
                            confirmButtonText: '确定',
                            closeOnClickModal:false,
                            type: 'error'
                            }).then(() => {
                                //window.location.reload();
                            }).catch(() => {
                                //window.location.reload();
                        });
                    }
                })

            }).catch(() => {
                //window.location.reload();    
            });
        },
        //输入code后调用postRecoverGroupPay(code,gid,cb)，获取到payid后刷新页面
        copygid(){
            var input = document.createElement("input"); // 创建input对象
            input.value = this.gid; // 设置复制内容
            document.body.appendChild(input); // 添加临时实例
            input.select(); // 选择实例内容
            document.execCommand("Copy"); // 执行复制
            document.body.removeChild(input); // 删除临时实例
        },
        sendMessage(){
            if(/^\s*$/.test(this.message)){
                return
            }
            var that = this
            console.log("发送消息")
            let msg = {
                "chatMsg":{
                    "senderId":that.uid,
                    "likeNum":0,
                    "dislikeNum":0,
                    "receiverId":0,
                    "senderAvatar":that.avatar,
                    "msg":that.message,
                    "msgId":0,
                    "senderName":that.nickname,
                    "type":globaldata.MSG_TYPE_TEXT,
                    "groupId":that.gid},
                "action":globaldata.MSG_ACTION_CHAT,
                "extend":""
            }
            console.log("meg", msg);
            ws.send(JSON.stringify(msg))
            that.coldtime=10
            that.message = ""
            
        },
        initroom(){
            console.log("开始初始化群聊")
            this.coldtime = 10
            
            //初始化群名和群id test
            this.gid = Number.parseInt(this.urlid,36);

            if(isNaN(this.gid) || this.urlid.length > 6){
                this.gid = 0;
                this.$alert('当前群聊不存在，请检查链接后重试', '进群失败', {
                        confirmButtonText: '确定',
                        callback: () => {
                            this.$emit("quite")
                        }
                });
                return
            }
            //根据gid获取详细信息
            let that = this;
            postGroupInfo(that.gid, (result)=>{
                //console.log("aaaaaa"+result)
                if(result.succeed){
                    //记录真实的群名
                    that.groupname = result.groupname
                    //动态显示当前的title
                    document.title = that.groupname + " - 在此群聊"
                    addHistoryGroup(that.groupname)
                    //this.$emit("updateHistory");
                    //获取最近20条聊天历史
                    postChatHistory(that.gid,0,20,0,(result)=>{
                        if(result.succeed){
                            //console.log("列表加载结果：",result)
                            for(var i=0;i<result.chatMsgs.length;i++){
                                /* result.chatMsgs结构
                                [
                                    {dislikeNum:0,
                                    receiverId:0,
                                    likeNum:0,
                                    type:1,
                                    senderName:km,
                                    msg:koiu,
                                    groupId:162,
                                    msgId:692,
                                    senderAvatar:k,
                                    senderId:100150},
                                    {},{}, ...
                                ]
                                */
                                var rawmsg = result.chatMsgs[i]
                                //判断当前用户有无点赞这个消息
                                var iflike = false
                                for(var j = 0 ; j < result.likeMids.length ; j++){
                                    if(rawmsg.msgId == result.likeMids[j]){
                                        iflike = true
                                    }
                                }
                                //追加数据
                                that.messagesList.unshift({
                                    msg:rawmsg.msg,
                                    msgId:rawmsg.msgId,
                                    type:rawmsg.type,
                                    senderName:rawmsg.senderName,
                                    senderAvatar:rawmsg.senderAvatar,
                                    senderId:rawmsg.senderId,
                                    likeNum:rawmsg.likeNum,
                                    ifLike:iflike
                                })
                                that.autoScrollToBottom()
                                
                            }
                            
                            console.log("列表加载完成")
                            //console.log(that.messagesList)
                        }else{
                            //console.log("列表加载失败",result)
                            //如果处理完后发现并没有列表，则追加一个系统提示
                            /*
                            if(that.messagesList.length == 0){
                                that.messagesList.unshift({
                                    msg:'群聊已创建，点击右上角“邀请"按钮，邀请朋友一起匿名群聊吧！',
                                    msgId:0,
                                    type:1,
                                    senderName:"在此群聊",
                                    senderAvatar:"💖",
                                    senderId:0,
                                    likeNum:1,
                                    ifLike:false
                                })
                            }*/
                        }
                        //请求欢迎消息
                        postGroupSysInfo(that.gid,(result)=>{
                            console.log("sysinfo:",result)
                            if(result == null){
                                return
                            }
                            if(result.sysinfo.length > 2){
                                that.messagesList.push({
                                    msg:result.sysinfo,
                                    msgId:0,
                                    type:1,
                                    senderName:result.sysname,
                                    senderAvatar:"💖",
                                    senderId:0,
                                    likeNum:1,
                                    ifLike:false
                                })
                                that.autoScrollToBottom()
                            }
                            
                        })
                        
                        
                        
                    })
                }
                else{

                    that.$confirm(result.message,'进群失败', {
                        confirmButtonText: '刷新页面',
                        showCancelButton:true,
                        cancelButtonText:"返回首页",
                        type:'error',
                        callback: action => {
                            if(action == 'cancel'){
                                that.$router.push('/');
                                console.log(action)
                            }else{
                                window.location.reload()
                            }
                            
                        }
                    });
                    
                }
                //console.log(this.messagesList)
                
            })
        
            //this.$socket.connect()
            //1秒1次更新冷却时间
            setInterval(() => {
                if(that.coldtime > 0){
                    that.cansend = false
                    that.coldtime = that.coldtime - 1
                    if(that.coldtime < 1){
                        that.cansend = true
                    }
                }
            }, 1000);

            //开启websocket
            this.startWebsocket()
            console.log("房间初始化结束")

            

        },
        judgeClient(){
            let p = navigator.platform;
            let u = navigator.userAgent;
            let is_android = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
            let is_ios = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
            if (p == "Win32" || p == "Win64" || p == "MacPPC" || p == "MacIntel" || p == "X11" || p == "Linux i686") {//PC调试环境
                return "web"
            }
            else {
                if (is_android) {//Android终端
                    return "adr"
                }
                else if (is_ios) {//IOS终端
                    return "ios"
                }
            }
        },
        showdoloadtips(){
            var that = this
            //显示下载tips的策略
            that.ifshowdownloadtips = false;
            //1、如果是web端，cookie记录关闭过的，不再开启，否则显示5秒后关闭；2、如果是ios端，始终显示5秒后关闭；3、安卓端不显示
            //首先判断来自哪个端
            let client = that.judgeClient()
            if (client == "web") {//web
                console.log('web init');
                //如果是web端，cookie记录关闭过的，不再开启，否则显示5秒后关闭
                var tips = getCookie('showdownloadtips');
                if(tips == "close"){
                    //如果tips被主动关过,不再显示tips
                    that.ifshowdownloadtips = false
                }else{
                    //等1分钟后再弹出tips
                    setTimeout(() => {
                        that.ifshowdownloadtips = true
                    }, 1000);
                    //tips显示15秒后关闭
                    setTimeout(() => {
                        that.ifshowdownloadtips = false
                    }, 45000);
                }
            }
            else {
                if (client == "adr") {//Android终端不展示，因为可能是app里打开的
                    console.log('Android init');
                    that.ifshowdownloadtips = false
                }
                else if (client == "ios") {//IOS终端
                    console.log('iOS init');
                    that.ifshowdownloadtips = true
                    //显示10秒后自动关闭tips
                    setTimeout(() => {
                        that.ifshowdownloadtips = false
                    }, 30000);
                    
                }
            }
            
        },
        closeTips(){
            this.ifshowdownloadtips = false
            setCookie('showdownloadtips',"close")
        },
        downloadapp(){
            let client = this.judgeClient()
            if (client == "web") {//web目前不做任何事
            }
            else if(client == "adr"){
                window.open('https://zaicires.oss-cn-hangzhou.aliyuncs.com/pkg/zaici.apk', '_blank');

            }
            else if(client == "ios"){//ios跳商店
                window.open('https://itunes.apple.com/cn/app/id1599916138?mt=8', '_blank');
            }
        },
        startWebsocket(){
            var that = this
            let socketurlbody = (globaldata.IS_RELEASE ? globaldata.SOCKET_PREFIX+globaldata.SERVER_HOST+":"+globaldata.SOCKET_PORT : globaldata.test_SOCKET_PREFIX+globaldata.test_SERVER_HOST+":"+globaldata.test_SOCKET_PORT )+ "/ws"
            ws = new WebSocket(socketurlbody);//填写地址
            ws.onopen = function() {
                console.log("Connection open ...");
                let msg = {
                "chatMsg":{
                    "senderId":that.uid,
                    "likeNum":0,
                    "dislikeNum":0,
                    "receiverId":0,
                    "senderAvatar":that.avatar,
                    "msg":"",
                    "msgId":0,
                    "senderName":that.nickname,
                    "type":globaldata.MSG_TYPE_SYS,
                    "groupId":that.gid},
                "action":globaldata.MSG_ACTION_CONNECT,
                "extend":""
                }
                ws.send(JSON.stringify(msg))
            };
            
            ws.onmessage = function(evt) {
                var message = JSON.parse(evt.data)
                //console.log("Received Message: ", message)
                //解析消息类型
                //登录信息
                if(message.action == 100){
                    console.log("普通聊天消息")
                    var rawmsg = message.chatMsg
                    //追加数据
                    that.messagesList.push({
                        msg:rawmsg.msg,
                        msgId:rawmsg.msgId,
                        type:rawmsg.type,
                        senderName:rawmsg.senderName,
                        senderAvatar:rawmsg.senderAvatar,
                        senderId:rawmsg.senderId,
                        likeNum:rawmsg.likeNum,
                        ifLike:false
                    })
                    //更新coldtime
                    console.log("测试信息")
                    console.log(message)

                    if(that.uid == rawmsg.senderId){
                        //更新冷却时间
                        var extendinfo = JSON.parse(message.extend)
                        that.coldtime = extendinfo.coldtime
                    }
                    //console.log(that.messagesList)
                    if(rawmsg.senderId == that.uid || that.$refs.content.scrollTop > that.$refs.content.scrollHeight - that.$refs.content.offsetHeight - 50){
                        //当前用户发送了消息，或需要自动置底
                        that.autoScrollToBottom()
                    }
                }
                else if(message.action == 101){
                    console.log("发送失败的消息")
                }
                else if(message.action == 200){
                    console.log("用户进群消息")
                    var joininfo = JSON.parse(message.extend)
                    /**joininfo结构 {membersnum: '2', groupid: '162', newcomercoldtime: '8', newcomeruid: '100150'} 注意，值都是string */
                    let gid = joininfo.groupid * 1
                    let uid = joininfo.newcomeruid * 1
                    let coldseconds = joininfo.newcomercoldtime
                    //console.log(joininfo)
                    if(gid == that.gid){
                        that.usersnum = joininfo.membersnum * 1 //更新群里人数
                        //如果当前uid是新进来的
                        if(that.uid == uid){
                            that.coldtime = coldseconds * 1 //更新冷却时间
                        }
                    }
                }
                else if(message.action == 201){
                    console.log("用户退群消息")
                    var leaveinfo = JSON.parse(message.extend)
                    //leaveinfo（注意值是字符串） : {membersnum: '1', leaveuid: '100139', groupid: '162'}
                    let gid = leaveinfo.groupid * 1
                    if(gid == that.gid){
                        that.usersnum = leaveinfo.membersnum * 1 //更新群里人数
                    }
                }
                else if(message.action == 300){
                    console.log("用户点赞消息")
                    var likeinfo = JSON.parse(message.extend)
                    /*likeinfo结构{uid: '100139', gid: '162', islike: '1', mid: '698'}
                    注意这里的服务端设计有点问题，键值全是字符串
                    */
                    let uid = likeinfo.uid * 1
                    let mid = likeinfo.mid * 1
                    let gid = likeinfo.gid * 1
                    let islike = likeinfo.islike * 1
                    //仅在群信息对，且消息是点赞消息时处理
                    if(gid == that.gid && islike == 1){
                        var j = 500 
                        for(var i = that.messagesList.length -1 ; i > -1 ; i--){
                            //最多循环检查最新的j条消息，j条以前的不刷新了，防止性能压力
                            if(j < 0){
                                break
                            }else{
                                j = j -1
                            }
                            if(that.messagesList[i].msgId == mid){
                                that.messagesList[i].likeNum = that.messagesList[i].likeNum + 1
                                //如果点赞者是当前用户本人，需要记录当前用户已喜欢此消息
                                if(uid == that.uid)
                                {
                                    that.messagesList[i].ifLike = true
                                }
                                console.log("更新点赞消息",that.messagesList[i])
                                break
                            }
                        }
                    }

                    
                }else{
                    console.log("收到的消息出错了")
                }
                
                //ws.close();
            };
            
            ws.onclose = function() {
                console.log("Connection closed.");
            }

            ws.onerror = function(evt){
                console.log("ws onerror ", evt);
            }
        },
        sendLike(msgId, index){
            //console.log("sendLike", msgId, index, ws)
            var that = this
            let msg = {
                "chatMsg":{
                    "senderId":that.uid,
                    "likeNum":1,
                    "dislikeNum":0,
                    "receiverId":0,
                    "senderAvatar":that.avatar,
                    "msg":"",
                    "msgId":msgId,
                    "senderName":that.nickname,
                    "type":globaldata.MSG_TYPE_SYS,
                    "groupId":that.gid},
                "action":globaldata.MSG_ACTION_LIKEMSG,
                "extend":this.uniqueid
                }
                //console.log(msg)
            ws.send(JSON.stringify(msg))
            this.messagesList[index].ifLike = true;
        },
        backhome(){
            //先存个历史记录
            addHistoryGroup(this.groupname)
            document.title = "随时随地，在此群聊"
            this.$emit("quite")
            /*
            let routeData = this.$router.resolve({
                name: '/'
            });
            window.open(https://itunes.apple.com/cn/app/id1599916138?mt=8, '_blank');
            */
        },
        share(){            
            this.dialogVisible = false
            var that = this
            /*
            this.$confirm('<img class="shareimg" src="https://cikephoto.oss-cn-hangzhou.aliyuncs.com/resources/qrcode-ios.png" /><div>长按二维码保存到本地，分享给朋友扫码进群吧！</div><div class="sharetext">或点击”复制群地址”获取分享链接</div>', '分享群聊', {
            */
           //首先判断来自哪个端
           let client = this.judgeClient()
           // 获取当前网页的 URL
            let currentURL = window.location.href;
            // 通过正则表达式提取 code（第一个/后）
            let code = currentURL.match(/\/(\w+)$/)[1];
            if (client == "web") {//web支持点击下载图片
                const htmlval = this.$refs.shareqrcode.$el.outerHTML
                this.$confirm(htmlval+'<div>保存二维码图片，扫码即可进群<div class="sharetext">或在首页输入本群群名或群口令: #'+code+'</div>', '邀请群聊 - '+this.groupname+'', {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: '保存二维码',
                    cancelButtonText: '复制群口令',
                    center: true
                }).then(() => {
                    //点击保存二维码，触发下载
                    var image = new Image();
                    //跨域会导致a标签在部分浏览器中会直接打开新标签页 解决跨域 Canvas 污染问题
                    image.setAttribute("crossOrigin", "anonymous");
                    image.onload = function () {
                        var canvas = document.createElement("canvas");
                        canvas.width = image.width;
                        canvas.height = image.height;
                
                        var context = canvas.getContext("2d");
                        context.drawImage(image, 0, 0, image.width, image.height);
                        var url = canvas.toDataURL("image/png");
                
                        // 生成一个a元素
                        var a = document.createElement("a");
                        // 创建一个单击事件
                        var event = new MouseEvent("click");
                
                        // 将a的download属性设置为我们想要下载的图片名称，若name不存在则使用‘下载图片名称’作为默认名称
                        a.download = that.groupname+".png";
                        // 将生成的URL设置为a.href属性
                        a.href = url;
                
                        // 触发a的单击事件
                        a.dispatchEvent(event);
                    };
                    image.src = document.getElementById('picurl').src;
                }).catch(() => {
                    //点击取消按钮后，复制群链接
                    var input = document.createElement("input"); // 创建input对象
                    //input.value = window.location.href; // 设置复制内容
                    input.value = "加入匿名群《"+this.groupname+"》,打开cikeshikong.com输入口令：#"+code
                    document.body.appendChild(input); // 添加临时实例
                    input.select(); // 选择实例内容
                    document.execCommand("Copy"); // 执行复制
                    document.body.removeChild(input); // 删除临时实例
                    const h = this.$createElement;
                    this.$notify({
                        title: '群口令已复制',
                        message: h('i', { style: 'color: teal'}, '邀请口令已复制，粘贴给好友一起群聊吧'),
                        duration:4000
                    });
                });
            }else{
                const htmlval = this.$refs.shareqrcode.$el.outerHTML
                this.$confirm(htmlval+'<div>长按二维码保存图片，分享朋友扫码进群</div><div class="sharetext">或在首页输入本群群名或群口令: #'+code+'</div>', '邀请群聊 - '+this.groupname+'', {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: '复制群口令',
                    cancelButtonText: '下载APP',
                    showConfirmButton:true,
                    distinguishCancelAndClose:true,
                    center: true
                }).then(() => {
                    //确定按钮复制链接
                    var input = document.createElement("input"); // 创建input对象
                    //input.value = window.location.href; // 设置复制内容
                    input.value = "加入匿名群《"+this.groupname+"》,打开cikeshikong.com输入口令：#"+code
                    document.body.appendChild(input); // 添加临时实例
                    input.select(); // 选择实例内容
                    document.execCommand("Copy"); // 执行复制
                    document.body.removeChild(input); // 删除临时实例
                    const h = this.$createElement;
                    this.$notify({
                        title: '群口令已复制',
                        message: h('i', { style: 'color: teal'}, '邀请口令已复制，粘贴给好友一起群聊吧'),
                        duration:4000
                    });

                    
                }).catch(action => {
                    if(action === 'cancel'){
                    //点击下载app
                        let routeData = this.$router.resolve({ path: '/download'});
                        window.open(routeData.href, '_blank');
                    
                    }
                });
            }
        

        },
        autoScrollToBottom(){
            this.$nextTick(()=>{
                var area = this.$refs.content;
                area.scrollTop = area.scrollHeight;
            })
        },
        loadingMoreMessages(){
            //向前loading更多消息时调用，一次loading20条
            var that = this
            var firstmsgid = that.messagesList[0].msgId
            let nowH = this.$refs.content.scrollHeight

            //获取最近20条聊天历史
            postChatHistory(that.gid,firstmsgid,20,0,(result)=>{
                if(result.succeed){
                    //console.log("列表加载结果：",result)
                    
                    for(var i=0 ; i<result.chatMsgs.length ; i++){
                        /* result.chatMsgs结构
                        [
                            {dislikeNum:0,
                            receiverId:0,
                            likeNum:0,
                            type:1,
                            senderName:km,
                            msg:koiu,
                            groupId:162,
                            msgId:692,
                            senderAvatar:k,
                            senderId:100150},
                            {},{}, ...
                        ]
                        */
                        var rawmsg = result.chatMsgs[i]
                        //判断当前用户有无点赞这个消息
                        var iflike = false
                        for(var j = 0 ; j < result.likeMids.length ; j++){
                            if(rawmsg.msgId == result.likeMids[j]){
                                iflike = true
                            }
                        }
                        //追加数据
                        that.messagesList.unshift({
                            msg:rawmsg.msg,
                            msgId:rawmsg.msgId,
                            type:rawmsg.type,
                            senderName:rawmsg.senderName,
                            senderAvatar:rawmsg.senderAvatar,
                            senderId:rawmsg.senderId,
                            likeNum:rawmsg.likeNum,
                            ifLike:iflike
                        })
                    }
                    console.log("加载更多20条完成")
                    this.$nextTick(()=>{
                        var area = this.$refs.content;
                        //area.scrollTop = area.scrollHeight - nowH;
                        area.scrollTop = area.scrollHeight - nowH;
                    })
                    
                    //console.log(that.messagesList)
                }else{
                    that.$message.info('近7天暂无更多消息了~');
                }
                
            })
        },
        scrollEvent () {
            //console.log(this.$refs.content.Height)
            if(this.$refs.content.scrollTop == 0){
                //记录现在的高度值
                this.loadingMoreMessages()
            }
        },
        // shift+回车换行，回车发送
        handkeyCode(e) {
            //console.log(e); // 打印出按键后的信息
            if(e.shiftKey && e.keyCode === 13){
                console.log('触发了shift+回车');
                return
            }
            else if(e.keyCode === 13){
                console.log('触发了回车');
                //发送消息
                this.sendMessage()
            }
        }

    },
    beforeDestroy() {
        ws.close();

        window.removeEventListener('keydown', this.handkeyCode, true)//关闭监听键盘按下事件

    },
    // 生命周期
    async created(){

        this.uid = getCookie('uid');
        this.uniqueid = getCookie('uniqueid');

        

        //初始化房间数据
        this.initroom()

        //判断当前用户有无进群权限
        this.checkPermission();

        document.addEventListener('visibilitychange', ()=>{
            if( document.visibilityState == "visible" ){
                //全局刷新开关，页面一出现就刷新；仅正式环境开启，测试环境方便调试
                if(globaldata.IS_RELEASE){
                    window.location.reload();
                }
                
            }
        })

        //设置meta
        var that = this
        setTimeout(() => {
            that.pageName = that.groupname+" - 在此群聊";
            that.metaList[0].content = "在此群聊，一起讨论"+that.groupname+"！免注册一键进群，讨论热门事件、新鲜事、突发情况，交流问题、爱好、经验，随时一起聊！"; // description
            that.metaList[1].content = "在此群聊,"+that.groupname+",临时群，群工具，免注册,匿名,实时,群聊"; //name="keywords"
        }, 2000)

        window.addEventListener('keydown', this.handkeyCode, true)//开启监听键盘按下事件
    }
}
</script>

<style lang="scss" scoped>


.chat-wrap{
    width: 100%;
    height: 100%;
    background: #F2F2F2;
    overflow: hidden;
}
.permission-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 280px;
  height: 340px;
  background-color: #fff;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 999;
  max-width: 100%;
  /*
  max-height: 100%;
  overflow: auto;*/
}

.corner {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  background: #ff3c55;
  clip-path: polygon(0 0, 100% 0, 100% 100%);
  border-radius: 0 6px 0 0; /* Same border-radius as the popup */
}

.diamond {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 20px;
  height: 20px;
}



.permission-popup .groupname {
  font-size: 20px;
  color: #333333;
  margin: 20px 50px 40px;
  word-wrap: break-word;
}

.permission-popup .intro {
  font-size: 14px;
  color: #999999;
  margin: 0 30px;
  word-wrap: break-word;
}

.permission-popup .icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 70px 15px 70px;
}

.permission-popup .icons .icon-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px; /* Add this line */
}

.permission-popup .icons img {
  width: 45px;
  height: 45px;
  object-fit: cover;
}

.permission-popup .icon-desc {
  font-size: 11px;
  color: #FACE33;
  margin-top: 5px;
}

.permission-popup .recharge {
  width: 220px;
  height: 45px;
  background-color:#ff3c55;/* #FF0034;*/
  color: #fff;
  font-size: 14px;
  border-radius: 22.5px; /* half of the height to create full round corners */
  border: none;
  cursor: pointer;
  margin: 30px auto 20px;
  display: block;
}
.permission-popup .recharge-disable {
  width: 220px;
  height: 45px;
  background-color:#666666;/* #FF0034;*/
  color: #fff;
  font-size: 14px;
  border-radius: 22.5px; /* half of the height to create full round corners */
  border: none;
  cursor: wait;
  margin: 30px auto 20px;
  display: block;
}

.permission-popup .recoverpaid {
  color: #CECECE;
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
}


.blur {
  filter: blur(4px);
  pointer-events: none;
}

/** header */
.chat-title{
    display: inline-block;
    text-align: left;
    margin: 0;
    height: 40px;
    vertical-align: middle;
    line-height: 40px;
    font-weight: 400;
    color: #aaaaaa;
    padding: 0 10px;
}
.shareimg{
    width: 150px;
    height: 150px;
}
.wxpayimg{
    width: 150px;
    height: 150px;
}
.header{
    min-height: 40px;
}
.header{
    width: 100%;
    height: 60px;
    background: #FCFCFC;
    text-align: left;
}
.homebtn{
    width: 25px;
    height: 25px;
    margin: 3px;
    margin-left: 15px;
    background: url("../assets/chat-back.png") no-repeat center center;
    background-size: contain;
    vertical-align: middle;
    cursor: pointer;
    user-select: none; /* 禁用文字选择 */
}
/** content */
.content{
    width: calc(100% - 5px);
    height: calc(100% - 110px);
    position: absolute;
    left: 0;
    top: 60px;
    overflow-y: scroll;
    background: #F2F2F2;
    /* padding: 2vh 0 2vh 0; */
    padding-left: 5px;
    scrollbar-width: none;

    &::-webkit-scrollbar{
        display: none;
    }
}
.content > div{
    padding: 2vh 0;;
}
/** footer */
.footer{
    width: 100%;
    min-height: 36px;
    position: absolute;
    bottom: 0;
    background: #FCFCFC;
    padding: 10px 0;
}
.footer-item{
    display:flex; 
    align-items:flex-end;
    justify-content: center;
    user-select: none; /* 禁用文字选择 */
}
.messaginput:focus{
    outline: none;
}
.messaginput::placeholder{
    color: #999999;
}
@media only screen and (max-width: 1023px) {
    .header div{
        display: inline-block;
        text-align: center;
    }
    
    .chat-title{
        /*宽度要减去 */
        width: calc(100% - 25px - 38px - 20%);
        font-size: 16px;
    }
    .sharebtn{
        width: 20%;
        height: 100%;
        right: 0;
        background-color: #FACE33;
        vertical-align: middle;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        border-width: 0px;
        user-select: none; /* 禁用文字选择 */
    }
    .messaginput{
        width: 65%;
        border: none;
        font-size: 15px;
        resize: none;
        height: 26px;
        padding: 7px;
    }
    .footer button{
        height: 36px;
        /*width: 20%;*/
        min-width: 90px;
        border: none;
        color: #fff;
        background-color: #F6CA33;
        font-size: 15px;
        padding: 7px;
    }
    .footer button.disabledsendbtn{
        background-color: #D9D9D9;
    }
    /* download-guide */
    .download-guide{
        position: absolute;
        top: 40px;
        z-index: 1;
        width: calc(100% - 10px);
        height: 30px;
        background-color: rgba(246, 198, 51, 0.6);
        color: #fff;
        font-weight: bold;
        padding: 4px 5px;
        vertical-align: middle;
    }
    .download-text{
        vertical-align: middle;
        font-size:14px;
    }
    .download-guide button{
        border: none;
        height: 30px;
        vertical-align: middle;
    }
    .download{
        margin-left: 20px;
        display: inline-block;
        position: relative;
    }
    .download button{
        background-color: #fff;
        border-radius: 20px;
        padding: 1px 5px;
        width: 100px;
    }
    .download img{
        width: 20px;
        vertical-align: text-bottom;
    }
    .download span{
        height: 20px;
        font-size: 14px;
        display: inline-flex;
        flex: 1;
        justify-content: center;
        color: #000;
    }
    .close {
        width: 0;
        height: 0;
        background: none;
        position: absolute;
        left: 0px;
        padding: 0;
    }
    .close img{
        width: 0;
        height: 0;
        /*height: 25px;*/
    }
}
@media only screen and (min-width: 1024px) {
    .header div{
        display: inline-block;
        text-align: center;
    }
    .chat-title{
        width: calc(90% - 25px - 38px);
        font-size: 16px;
    }
    .sharebtn{
        width: 10%;
        height: 100%;
        right: 0;
        background-color: #FACE33;
        vertical-align: middle;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        border-width: 0px;
        cursor: pointer;
    }
    .messaginput{
        width: 40%;
        min-height: 26px;
        border: none;
        font-size: 15px;
        resize: none;
    }
    .messaginput::placeholder{
        color: #999999;
    }
    .messaginput, .footer button{
        padding:7px;
    }
    .footer button{
        height: 40px;
        width: 10%;
        min-width: 90px;
        max-width: 150px;
        border: none;
        color: #fff;
        background-color: #FACE33;
        font-size: 15px;
    }
    .footer button.disabledsendbtn{
        background-color: #D9D9D9;
    }
    /* download-guide */
    .download-guide{
        position: absolute;
        top: 40px;
        z-index: 1;
        width: calc(100% - 10px);
        height: 30px;
        background-color: rgba(246, 198, 51, 0.5);
        color: #fff;
        font-weight: bold;
        padding: 10px 20px;
        vertical-align: middle;
    }
    .download-text{
        vertical-align: middle;
        font-size: 16px;
    }
    .download-guide button{
        border: none;
        height: 30px;
        vertical-align: middle;
    }
    .download{
        margin-left: 20px;
        display: inline-block;
        position: relative;
    }
    .download button{
        background-color: #fff;
        border-radius: 20px;
        padding: 1px 5px;
        width: 100px;
    }
    .download button::after {
        content:"";
        position: absolute;
        left: -55px;
        top: 35px;
        z-index: 99;
        width: 250px;
        height: 250px;
        border-radius: 4px;
        transform-origin: top;
        background-image: url("https://cikephoto.oss-cn-hangzhou.aliyuncs.com/resources/appqr.png");
        background-repeat: no-repeat;
        background-size: contain;
        
        transform: scale(0);
        opacity: 0;
        -webkit-transition: all .2s ease-in-out;
        -o-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
        
    }
    .download button:hover::after{
        transform:scale(1);
        opacity: 1;
    }
    .download img{
        width: 20px;
        vertical-align: text-bottom;
    }
    .download span{
        height: 20px;
        font-size: 14px;
        display: inline-flex;
        flex: 1;
        justify-content: center;
        color: #000;
    }
    .close {
        background: none;
        position: absolute;
        right: 50px;
    }
    .close img{
        height: 25px;
    }
}
</style>